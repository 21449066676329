const redirectToApp = {
    init: () => {
        const redirectButtons = document.querySelectorAll('#redirect-app')

        if ( redirectButtons ) {
            redirectButtons.forEach(button => {
                const redirect_wrapper = button.parentElement;

                const input = redirect_wrapper.querySelector('input[type=email]');
            
                button.addEventListener('click', e => {
                    e.preventDefault();

                    const email = input.value;

                    const link = e.target.getAttribute('href');

                    if ( email && email.length > 0 ) {
                        var prepareLink = new URL(link);
                        
                        prepareLink.searchParams.set('email', email);

                        window.location = prepareLink.href;
                    } else {
                        const invalid = redirect_wrapper.querySelector('#invalid').textContent = 'Please complete this required field.';
                    }
                });
            });
        }
    }
}

export default redirectToApp;