const requestDemo = {
    init: () => {
        let formIsInit = false;
        const requestDemoForm = document.querySelector('.request-demo__form');

        if (!requestDemoForm) return;

        const formInitInterval = setInterval(() => {
            if (formIsInit) {
                clearInterval(formInitInterval);
                return;
            }

            if (document.querySelector('#hsForm_90259032-a267-46d0-8b9d-159cedca93a0') && Object.keys(requestFormTranslations).length) {
                formIsInit = true;
                requestDemo.fixFields();
                requestDemo.setupDropdown('#demo__country-90259032-a267-46d0-8b9d-159cedca93a0', true);
                requestDemo.setupDropdown('#demo__company_size_range-90259032-a267-46d0-8b9d-159cedca93a0');
                requestDemo.showNextFields();
            }
        }, 300);
    },

    showNextFields: () => {
        const emailInput = document.querySelector('.request-demo__form input[name="email"]');
        const thirdColumn = document.querySelector('.request-demo__form .form-columns-2:nth-of-type(3)');
        const fourthColumn = document.querySelector('.request-demo__form .form-columns-1:nth-of-type(4)');
        const fifthColumn = document.querySelector('.request-demo__form .form-columns-1:nth-of-type(5)');

        if (!emailInput) return;

        let typingTimer;
        const typingDelay = 500;

        const checkInputs = () => {
            const isAllFieldsFilled = emailInput.value.trim() !== '';
            const isNoErrors = !emailInput.classList.contains('error');
            const isEmailValid = emailInput.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

            if (isAllFieldsFilled && isNoErrors && isEmailValid) {
                if (thirdColumn) thirdColumn.classList.add('active');
                if (fourthColumn) fourthColumn.classList.add('active');
                if (fifthColumn) fifthColumn.classList.add('active');
            } else {
                if (thirdColumn) thirdColumn.classList.remove('active');
                if (fourthColumn) fourthColumn.classList.remove('active');
                if (fifthColumn) fifthColumn.classList.remove('active');
            }
        };

        const handleInput = () => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(checkInputs, typingDelay);
        };

        emailInput.addEventListener('input', handleInput);
    },

    setupDropdown: (selector, enableSearch = false) => {
        const select = document.querySelector(selector);
        if (!select) return;

        const selectOptions = [...select.querySelectorAll('option')];
        selectOptions.shift();

        const selectInput = select.closest('.input');

        selectInput.insertAdjacentHTML('beforeend', `
        <div class="request-demo__select-dropdown">
            ${enableSearch ? `
                <div class="request-demo__select-dropdown__input">
                    <input type="text" data-select-dropdown-search placeholder="Search ...">
                </div>` : ''}
            <ul class="request-demo__select-dropdown__list">
                ${selectOptions.map((option, idx) => `<li data-select-search-option="${idx + 1}" data-value="${option.value}">${option.textContent}</li>`).join('')}
            </ul>
        </div>
    `);

        const dropdown = selectInput.querySelector('.request-demo__select-dropdown');
        const dropdownList = selectInput.querySelector('.request-demo__select-dropdown__list');
        const listItems = [...dropdownList.querySelectorAll('li')];

        if (enableSearch) {
            const searchInput = selectInput.querySelector('[data-select-dropdown-search]');
            selectInput.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                dropdown.classList.toggle('active');
                searchInput.focus();
            });

            searchInput.addEventListener('input', (event) => {
                const searchTerm = event.target.value.toLowerCase();
                listItems.forEach(item => {
                    if (item.textContent.toLowerCase().includes(searchTerm)) {
                        item.style.display = '';
                    } else {
                        item.style.display = 'none';
                    }
                });
            });
        } else {
            selectInput.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                dropdown.classList.toggle('active');
            });
        }

        listItems.forEach(item => {
            item.addEventListener('click', (e) => {
                e.stopPropagation();
                select.value = item.getAttribute('data-value');
                select.dispatchEvent(new Event('input', {bubbles: true}));
                dropdown.classList.remove('active');
            });
        });

        document.addEventListener('click', (event) => {
            if (!selectInput.contains(event.target)) {
                dropdown.classList.remove('active');
            }
        });
    },

    fixFields: () => {
        const form = document.querySelector('#hsForm_90259032-a267-46d0-8b9d-159cedca93a0');

        if (!form) return;

        const formElements = [
            {selector: '.hs_email span', property: 'textContent', translationKey: 'emailLabel'},
            {selector: '.hs_email input', property: 'placeholder', translationKey: 'emailPlaceholder'},
            {selector: '.hs_firstname span', property: 'textContent', translationKey: 'nameLabel'},
            {selector: '.hs_firstname input', property: 'placeholder', translationKey: 'firstnamePlaceholder'},
            {selector: '.hs_lastname input', property: 'placeholder', translationKey: 'lastnamePlaceholder'},
            {selector: '.hs_demo__country span', property: 'textContent', translationKey: 'countryLabel'},
            {
                selector: '.hs_demo__country select',
                property: 'innerHTML',
                translationKey: 'countryPlaceholder',
                isOption: true
            },
            {
                selector: '.hs_demo__company_size_range span',
                property: 'textContent',
                translationKey: 'companySizeLabel'
            },
            {
                selector: '.hs_demo__company_size_range select',
                property: 'innerHTML',
                translationKey: 'companySizePlaceholder',
                isOption: true
            },
            {selector: '.hs_phone span', property: 'textContent', translationKey: 'phoneLabel'},
            {selector: '.hs_demo__how_can_we_help_you_ span', property: 'textContent', translationKey: 'messageLabel'},
            {
                selector: '.hs_demo__how_can_we_help_you_ textarea',
                property: 'placeholder',
                translationKey: 'messagePlaceholder'
            },
            {selector: '.legal-consent-container p', property: 'innerHTML', translationKey: 'termsLabel'},
            {selector: '.hs-button', property: 'value', translationKey: 'buttonLabel'}
        ];

        formElements.forEach(({selector, property, translationKey, isOption}) => {
            const element = form.querySelector(selector);
            if (element) {
                if (isOption) {
                    element.options[0][property] = requestFormTranslations[translationKey];
                } else {
                    element[property] = requestFormTranslations[translationKey];
                }
            }
        });
    }
}

export default requestDemo;