import Swiper, { Navigation, Pagination } from 'swiper';

const selectedRelatedPosts = {
    settings: {
        carousels: '.selected-related-posts .swiper',
        nextArrow: '.selected-related-posts .carousel__arrow--right',
        prevArrow: '.selected-related-posts .carousel__arrow--left',
        pagination: '.selected-related-posts .carousel__pagination'
    },
    init: () => {
        let carousels = document.querySelectorAll(selectedRelatedPosts.settings.carousels);

        if (!carousels)
            return;

        const customer = document.querySelector('.single-customers');

        carousels.forEach((carousel) => {
            let carouselObj = new Swiper(carousel, {
                modules: [ Navigation, Pagination ],
                slidesPerView: 3,
                spaceBetween: (customer ? 37 :46),
                loop: true,
                navigation: {
                    nextEl: document.querySelector(selectedRelatedPosts.settings.nextArrow),
                    prevEl: document.querySelector(selectedRelatedPosts.settings.prevArrow),
                },
                pagination: {
                    el: carousel.querySelector(selectedRelatedPosts.settings.pagination),
                    clickable: true,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    660: {
                        slidesPerView: 2,
                        spaceBetween: (customer ? 10 : 28),
                    },
                    1240: {
                        slidesPerView: 3,
                        spaceBetween: (customer ? 37 : 46)
                    }
                }
            });
        });
    }
}

export default selectedRelatedPosts;