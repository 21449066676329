const promo = {
    settings: {
        endDate: null
    },
    init: () => {
        const timerColumn = document.querySelector('.timer-column');
        
        if (timerColumn) {
            promo.settings.endDate = new Date(timerColumn.getAttribute('data-end-date'));
    
            promo.updateTime();
    
            setInterval(() => {
                promo.updateTime();
            }, 60000);
        }

    },
    updateTime: () => {
        const currentDate = new Date();

        var diffMs = (promo.settings.endDate - currentDate); // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        document.querySelector('#timer-days').innerHTML = diffDays;
        document.querySelector('#timer-hours').innerHTML = diffHrs;
        document.querySelector('#timer-minutes').innerHTML = diffMins;
    }
}

export default promo;