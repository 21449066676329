const mediaTwoColumns = {
    init() {
        this.isPopup();
    },
    isPopup() {
        const media = document.querySelector('.media');

        if (media) {
            if (media.classList.contains('media-yt')) {
                this.popup();
            }
        }
    },
    popup() {
        const openEls = document.querySelectorAll('[data-yt-popup-open]');
        const close = document.querySelectorAll('[data-yt-popup-close]');

        openEls.forEach(openEl => openEl.addEventListener('click', this.popupOpen.bind(this, openEl)));
        close.forEach(closeEl => closeEl.addEventListener('click', () => {
            const popup = document.querySelector(`[data-yt-popup="${closeEl.dataset.ytPopupClose}"]`);

            if (!popup) return;

            this.popupClose(popup);
        }));

        document.addEventListener('keydown', evt => {
            if (evt.key === 'Escape') {
                const popup = document.querySelector('.media-popup.var_open');

                if (popup) {
                    this.popupClose(popup);
                }
            }
        });
    },
    popupOpen(openEl) {
        if (!openEl) return;

        const popup = document.querySelector(`[data-yt-popup="${openEl.dataset.ytPopupOpen}"]`);

        popup.classList.add('var_open');

    },
    popupClose(popup) {
        popup.classList.remove('var_open');
        const videos = document.querySelectorAll('iframe, video');

        videos.forEach(function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        })
    }
}

export default mediaTwoColumns;