const footer = {
    settings: {
        basicHeight: [],
        isListenerSet: false
    },
    init: function () {
        if (window.matchMedia('(max-width: 1023px)').matches) {
            this.setAccordions();
        } else {
            this.resetAccordions();
        }

        window.addEventListener('resize', this.handleResize.bind(this));
    },
    setAccordions: function () {
        const accordions = document.querySelectorAll('.footer__item');

        if (this.settings.isListenerSet) return;

        accordions.forEach((accordion, index) => {
            this.settings.basicHeight[index] = (accordion.offsetHeight);

            accordion.style.height = `${this.settings.basicHeight[index]}px`;
            const submenu = accordion.querySelector('.footer__submenu');
            submenu.style.opacity = 0;

            accordion.addEventListener('click', e => {
                const height = accordion.offsetHeight;
                const title = accordion.querySelector('.footer__link');
                const submenyHeight = submenu.offsetHeight;

                accordion.style.height = `${height + submenyHeight}px`;

                if (accordion.classList.contains('var-open')) {
                    accordion.style.height = `${this.settings.basicHeight[index]}px`;

                    setTimeout(() => {
                        submenu.style.opacity = 0;
                    }, 300);
                } else {
                    accordion.style.height = `${height + submenyHeight}px`;
                    submenu.style.opacity = 1;
                }


                accordion.classList.toggle('var-open');
            })
        })

        this.settings.isListenerSet = true;
    },
    resetAccordions: function () {
        const accordions = document.querySelectorAll('.footer__item');

        accordions.forEach((accordion, index) => {
            accordion.removeAttribute('style');
            accordion.classList.remove('var-open');
        })
    },
    handleResize: function () {
        if (window.matchMedia('(max-width: 1023px)').matches) {
            this.setAccordions();
        } else {
            this.resetAccordions();
        }
    }
}

export default footer