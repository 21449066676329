const nav = {
	hamburger: () => {
		let hamburgerBreakpoint = 1030;
		let hamburger = document.querySelector('.hamburger');
		if (!hamburger)
			return;

		let container = document.querySelector('.header');
		hamburger.addEventListener('click', () => {
			hamburger.classList.toggle('hamburger--active');
			container.classList.toggle('header--active');
			nav.hideSubmenus();
		})

		if(window.innerWidth < hamburgerBreakpoint) {
			let menuItem = document.querySelectorAll('.menu__link.menu__link--has-children');
			menuItem.forEach((item) => {
				item.addEventListener('click', (e) => {
					e.preventDefault();
					item.classList.toggle('menu__link--active');
				});
			});

			const wpmlItem = document.querySelector('.wpml-ls-item');

			window.addEventListener('click', () => {
				wpmlItem.classList.add('no-hover');
				
				const active_wpml = document.querySelector('.wpml-ls-item .menu__link--active')
				if (active_wpml) {
					active_wpml.classList.remove('menu__link--active');
				}
			});

			wpmlItem.addEventListener('click', (event) => {
				wpmlItem.classList.remove('no-hover');
				event.stopPropagation();
			});
		}
	},

	submenus: () => {
		nav.addTheAbilityToShowSubmenu();
		nav.addTheAbilityToHideSubmenu();
	},

	addTheAbilityToShowSubmenu: () => {
		document.querySelectorAll('.extended-menu__top_level_link.has_submenu').forEach(link => {
			link.addEventListener('click', () => {
				link.parentNode.children[1].classList.add('active');
			});
		});
	},

	addTheAbilityToHideSubmenu: () => {
		document.querySelectorAll('.extended-menu__submenu__go_back').forEach(link => {
			link.addEventListener('click', () => {
				nav.hideSubmenus();
			});
		});
	},

	hideSubmenus: () => {
		document.querySelectorAll('.extended-menu__submenu').forEach(submenu => {
			submenu.classList.remove('active');
		});
	},

	langMenu: () => {
		let langMenuLink = document.querySelector('.footer-menu__link--has-children');
		if (!langMenuLink)
			return;

		langMenuLink.addEventListener('click', (e) => {
			e.preventDefault();
			langMenuLink.classList.toggle('footer-menu__link--toggled');
		})
	}
}

export default nav;