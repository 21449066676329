import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';

const testimonialsSlider = {
    settings: {
        testimonials: '.testimonials-slider .swiper',
        nextArrow: '.testimonials-slider .testimonial-button-next',
        prevArrow: '.testimonials-slider .testimonial-button-prev',
        pagination: '.testimonials-slider .testimonial-pagination'
    },
    init: () => {
        let testimonials = document.querySelectorAll(testimonialsSlider.settings.testimonials);

        if (!testimonials)
            return;

        testimonials.forEach((testimonial) => {
            let carouselObj = new Swiper(testimonial, {
                modules: [ EffectFade, Navigation, Pagination ],
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                speed: 1000,
                effect: "fade",
                autoHeight: true,
                fadeEffect: {
                    crossFade: true,
                },
                navigation: {
                    nextEl: document.querySelector(testimonialsSlider.settings.nextArrow),
                    prevEl: document.querySelector(testimonialsSlider.settings.prevArrow),
                },
                pagination: {
                    el: testimonial.querySelector(testimonialsSlider.settings.pagination),
                    clickable: true,
                },
            });
        });
    }
}

export default testimonialsSlider;