import Masonry from 'masonry-layout';

const testimonialsTiles = {
    init() {
        const sections = document.querySelectorAll('.testimonials-tiles');

        if (!sections.length) return;

        sections.forEach(section => {
            this.setMasonry(section);
            this.showMore(section);
            this.customGradient(section);
        });
    },
    setMasonry(section) {
        const grid = section.querySelector('.grid');

        if (!grid) return;

        const masonryInstance = new Masonry(grid, {
            percentPosition: true,
            gutter: 24
        });

        section.masonryInstance = masonryInstance;
    },
    showMore(section) {
        const btnShowMore = section.querySelector('.testimonials-tiles__see-more');
        const hideTestimonials = section.querySelectorAll('.testimonials-tiles__testimonial:nth-child(n + 6)');

        if (!btnShowMore) return;

        btnShowMore.addEventListener('click', () => {
            if (btnShowMore.classList.contains('var_fewer')) {
                hideTestimonials.forEach(testimonial => {
                    testimonial.classList.remove('show');
                });

                setTimeout(() => {
                    section.masonryInstance.layout();
                    btnShowMore.removeAttribute('style');
                }, 400);

                btnShowMore.style.pointerEvents = 'none';
                btnShowMore.classList.remove('var_fewer');
            } else {
                hideTestimonials.forEach(testimonial => {
                    testimonial.classList.add('show');
                });
                section.masonryInstance.layout();

                btnShowMore.classList.add('var_fewer');
            }
        });
    },

    customGradient(section) {
        const customGradient = section.querySelector('[data-testimonials-tiles-custom-gradient]');

        if (!customGradient) return;

        if (window.innerWidth < 1024) return;

        const grid = section.querySelector('.grid');

        if (!grid) return;

        const gradientInner = customGradient.innerHTML;
        const newGradients = grid.clientHeight / 600;

        let integerPart = Math.floor(newGradients);
        let fractionalPart = newGradients - integerPart;
        let newGradientsArray = new Array(integerPart).fill(1);
        if (fractionalPart > 0) {
            newGradientsArray.push(fractionalPart);
        }

        let cumulativeTop = 0;
        let gradientNumberConst = 400;

        if (grid.clientHeight > 1800) gradientNumberConst = 450;

        newGradientsArray.forEach((number, idx, array) => {

            const gradient = document.createElement('div');
            gradient.classList.add('testimonials-tiles__gradient', 'testimonials-tiles__gradient--custom', 'testimonials-tiles__gradient--custom--new');
            gradient.innerHTML = gradientInner;

            if (idx === array.length - 1) {
                gradient.style.top = `${(cumulativeTop - gradientNumberConst) + (number * gradientNumberConst)}px`
                section.appendChild(gradient);
                return;
            }

            gradient.style.top = `${cumulativeTop}px`;
            cumulativeTop += number * gradientNumberConst;

            section.appendChild(gradient);
        });
    }


};

export default testimonialsTiles;
