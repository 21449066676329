import Swiper, { Pagination } from 'swiper';

const caseStudies = {
    init: () => {
        const swiper = document.querySelector('.case-studies .swiper');

        if ( !swiper ) return;

        new Swiper(swiper, {
            modules: [ Pagination ],
            slidesPerView: 1,
            spaceBetween: 20,

            breakpoints: {
                567: {
                    slidesPerView: 2,
                    spaceBetween: 8
                }
            },

            pagination: {
                el: document.querySelector('.case-studies .swiper-pagination'),
                clickable: true,
            },

        });
    }
}

export default caseStudies;