const heroTwoButtons = {
    init: function() {
        this.captureSubmitSignup();
        this.captureSubmitFold();
    },
    captureSubmitFold: () => {
        const container = document.querySelector('.hero-two-buttons__buttons');
        const containerInput = document.querySelector('.hero-two-buttons__input');
        const bottomContainer = document.querySelector('.hero-two-buttons__bottom');
        const submitBtn = container?.querySelector('a');

        if ( containerInput ) {
            const input = containerInput.querySelector('input');

            submitBtn.addEventListener('click', e => {
                e.preventDefault();
                const email = input.value;


                if ( email.length === 0 ) {
                    container.classList.toggle('var_unfold');
                    input.classList.toggle('var_fold');
                    bottomContainer.classList.toggle('var_unfold');
                } else {
                    heroTwoButtons.prepareURL(e.target, input, 'fold', email)
                }
            })
        }
    },
    captureSubmitSignup: () => {
        const submitBtn = document.querySelector('.hero-two-buttons__sign-up button');
        
        if ( submitBtn ) {
            const input = document.querySelector('.hero-two-buttons__sign-up input');

            submitBtn.addEventListener('click', e => {
                e.preventDefault();

                const email = input.value;
                heroTwoButtons.prepareURL(e.target, input, 'signup', email)
            })
        }
    },
    prepareURL: (target, input, type, email) => {
        const test = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)

        if (test) {
            const redirectUrl = type === 'fold' ? target.getAttribute('href') : target.getAttribute('data-url'); 
            const prepareUrl = redirectUrl.replace('URLencodedemailaddress', email);
            input.classList.remove('var_error');

            window.location.href = prepareUrl;
            if ( type === 'fold' ) {
                input.value = '';
            }
        } else {
            input.classList.add('var_error');
        }
    }
}

export default heroTwoButtons;