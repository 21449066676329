const roiCalculator = {
    init: function () {
        const rangeSliders = document.querySelectorAll('.range-input input[type="range"]');
        const slidersLabels = document.querySelectorAll('.value-label');
        
        if (!rangeSliders) {
            return;
        }

        rangeSliders.forEach(slider => {
            slider.addEventListener('input', this.changeValues);
        })

        slidersLabels.forEach(label => {
            label.addEventListener('drag', this.dragLabels);
        })
    },
    changeValues: function (e) {
        const sliders = Array.from(document.querySelectorAll('.range-input input[type="range"]'));
        const outputLabels = new Map(
            Array.from(document.querySelectorAll('.value-label')).map(label => [label.getAttribute('for'), label])
        );
        const { basic, enterprise, professional } = scripts_vars.pricing_plans;
        const annualCost = document.querySelector('#annual-cost');
        const savingsEl = document.querySelector('#savings');
        const roiEl = document.querySelector('#roi');
        let projectsNumber = 0;

        //change output position
        const prevSibling = e.target.previousSibling.previousSibling;
        
        roiCalculator.changeLabelPosition(prevSibling, e.target);        
    
        const costs = []
        const savings = [];
    
        sliders.forEach(slider => {
            const { name, value } = slider;
            const parsedValue = parseFloat(value);
            
            costs.push({ name, value });

            if (name === 'projects_number') projectsNumber = parsedValue;
    
            let savingValue = parsedValue;
            if (name === 'versions_number') savingValue *= 0.8;
            if (name === 'review_time') savingValue *= 0.9;
            savings.push({ name, value: savingValue });
    
            if (outputLabels.has(name)) {
                outputLabels.get(name).querySelector('.output-value').textContent = parsedValue.toLocaleString();
            }
        });

        const usedPlan = projectsNumber <= 10 ? basic : (projectsNumber <= 25 ? professional : enterprise);
    
        const calculatedCosts = roiCalculator.calculateValues(costs);
        const calculatedSaving = roiCalculator.calculateValues(savings);
        const finalSavings = (calculatedCosts - calculatedSaving - usedPlan);
        
        annualCost.textContent = roiCalculator.formatNumber(calculatedCosts);
        savingsEl.textContent = finalSavings > 0 ? roiCalculator.formatNumber(finalSavings) : 'N/A' ;
        roiEl.textContent = finalSavings > 0 ? (finalSavings / usedPlan).toLocaleString(undefined, {style: 'percent'}) : 'N/A';
    },
    calculateValues: (values) => {
        if (!Array.isArray(values)) {
            console.error('Argument must be a type of array');
            return;
        }

        return values.reduce((acc, reducer) => {
            reducer.name === 'review_time' ? acc *= reducer.value / 60 : acc *= reducer.value;
            
            return acc;
        }, 1);
    },
    formatNumber: (number) => {
        if (Number.isInteger(number)) {
            return number.toLocaleString();
        }
    
        number = Number(number.toFixed(2));
    
        const decimalPart = number.toString().split('.')[1] || "";
        const minFractionDigits = decimalPart.length === 1 ? 2 : 0;
    
        return number.toLocaleString(undefined, { 
            minimumFractionDigits: minFractionDigits, 
            maximumFractionDigits: 2 
        });
    },
    changeLabelPosition: (label, target) => {
        requestAnimationFrame(() => {
            const sliderRect = target.getBoundingClientRect();
            const labelRect = label.getBoundingClientRect();
            const min = parseFloat(target.min);
            const max = parseFloat(target.max);
            const value = parseFloat(target.value);
    
            const percentage = (value - min) / (max - min);
            let leftPosition = percentage * sliderRect.width;
    
            leftPosition -= labelRect.width / 2;
    
            if (leftPosition < 0) {
                leftPosition = 0;
            } else if (leftPosition + labelRect.width > sliderRect.width) {
                leftPosition = sliderRect.width - labelRect.width;
            }
    
            label.style.left = `${leftPosition}px`;
        });
    }    
}

export default roiCalculator;