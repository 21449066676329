const pricingTable = {
    initPopovers: () => {
        document.querySelectorAll('.pricing-table__feature.with-tooltip').forEach((item) => {
            item.addEventListener('mouseenter', (e) => {
                item.classList.add('hovered');
            });

            item.addEventListener('mouseleave', (e) => {
                item.classList.remove('hovered');
            });
        });
    },
    initPriceSwitcher: () => {
        let switcher = document.querySelector('.switcher');
        if (!switcher)
            return;

        let switches = document.querySelectorAll('[data-switch]');

        switches.forEach((item) => {
            item.addEventListener('click', (e) => {
                const switcher = e.target;
                const switcherVersion = switcher.getAttribute('data-switch');
                const promoYear = document.querySelector('.switcher__discount--year');

                if (!switcher.classList.contains('switcher__item--green')) {
                    document.querySelector('.switcher__item--green').classList.remove('switcher__item--green');
                    switcher.classList.add('switcher__item--green');
                }

                if (switcherVersion === 'month') {
                    promoYear.classList.remove('var_invisible');
                } else {
                    promoYear.classList.add('var_invisible');
                }

                let period = item.getAttribute('data-switch');
                document.querySelectorAll('.price-toggler').forEach((toggler) => {
                    toggler.textContent =
                        period === 'month'
                            ? toggler.dataset.priceMonth
                            : toggler.dataset.priceYear;
                });
            });
        });
    },
    accordionTable: () => {
        const toggle = document.querySelector('.plans-block__header');
        const tableContainer = document.querySelector('.plans');

        toggle?.addEventListener('click', function () {
            if (!this.classList.contains('var_open')) {
                tableContainer.classList.add('var_open');
                this.classList.add('var_open');
            } else {
                tableContainer.classList.remove('var_open');
                this.classList.remove('var_open');
            }
        })
    }
}

export default pricingTable;