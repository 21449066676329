const progressbar = {
    init: () => {
        let progressbar = document.getElementById("single-post-progressbar");
        if (!progressbar)
            return;

        window.onscroll = function() {
            let windowScroll = document.body.scrollTop || document.documentElement.scrollTop,
                height = document.documentElement.scrollHeight - document.documentElement.clientHeight - document.getElementById('footer').offsetHeight,
                scrolled = (windowScroll / height) * 100;

            progressbar.style.width = scrolled + "%";
        }
    }
}

export default progressbar;