const loadMore = {
	init: () => {
		let loadMore = document.querySelectorAll('[data-load-more]');
		if (!loadMore)
			return;

		loadMore.forEach((item) => {
			item.addEventListener('click', (e) => {
				e.preventDefault();

				let offset = parseInt(item.getAttribute('data-offset'))
				item.setAttribute('data-offset', offset + parseInt(item.getAttribute('data-per-page')));
				item.setAttribute('disabled', 'disabled');

				const dataToSend = new FormData();
				dataToSend.append("offset", offset);
				dataToSend.append("action", 'load_more_posts');

				const xhr = new XMLHttpRequest();
				xhr.onreadystatechange = function()
				{
					if(xhr.readyState === 4 && xhr.status === 200)
					{
						let blogItems = document.querySelector('.blog__items');
						blogItems.innerHTML += xhr.responseText;

						item.removeAttribute('disabled');
						if(parseInt(item.getAttribute('data-offset')) >= parseInt(item.getAttribute('data-max-posts'))) {
							item.remove();
						}
					}
				}

				xhr.open("POST", scripts_vars.ajax_url, true);
				xhr.send(dataToSend);
			});
		});
	}
}

export default loadMore;