import './../css/_app.scss'
import AOS from 'aos';
//import 'lity';
import nav from './nav';
import link from './link';
import pricingTable from './pricing-table';
import loadMore from './load-more';
import progressbar from "./progressbar";
import carousel from './carousel';
import promo from './promo';
import selectedCategoryPosts from './selected-category-posts';
import selectedRelatedPosts from './selected-related-posts';
import copy from './copy';
import faq from './faq';
import redirectToApp from './redirect-to-app';
import blogSideBar from './blog-side-bar';
import heroTwoButtons from './hero-two-buttons';
import testimonialsSlider from './testimonials-slider';
import mediaTwoColumns from './media-two-columns';
import caseStudies from './case-studies-listing';
import testimonialsTiles from './testimonials-tiles';
import footer from './footer';
import requestDemo from './request-demo';
import headerTransparent from './header-transparent';
import roiCalculator from './roi-calculator';

export default class App {
    constructor() {
        this.initDev(); // tests and shit
        this.initBody(); // runs on initialize & after barba
    }

    initBody() {
        blogSideBar.init();
        nav.hamburger();
        nav.langMenu();
        nav.submenus();
        AOS.init();
        heroTwoButtons.init();
        redirectToApp.init();
        pricingTable.initPopovers();
        pricingTable.initPriceSwitcher();
        pricingTable.accordionTable();
        link.init();
        loadMore.init();
        progressbar.init();
        carousel.init();
        promo.init();
        selectedCategoryPosts.init();
        selectedRelatedPosts.init();
        copy.init();
        faq.init();
        testimonialsSlider.init();
        mediaTwoColumns.init();
        caseStudies.init();
        testimonialsTiles.init();
        footer.init();
        setTimeout(() => requestDemo.init());
        headerTransparent.init();
        roiCalculator.init();
    }

    initCore() {

    }

    initDev() {

    }
}


function LoadApp() {
    const app = new App();
    app.initCore(); // runs on initialize
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', LoadApp);
} else {
    LoadApp();
}