const link = {
    init: () => {
        let links = document.querySelectorAll('[data-href]');
        if (!links)
            return;

        links.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                location.href = item.getAttribute('data-href');
            });
        });
    }
}

export default link;