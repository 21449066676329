const blogSideBar = {
    init: () => {
        const blog = document.querySelector('.blog');

        if ( !blog ) return;

        blogSideBar.scrollTo();
        blogSideBar.highlightElements();

        
    },
    scrollTo: () => {
        const headerHeight = document.querySelector('.header').offsetHeight;
        const allLinks = document.querySelectorAll('a.ez-toc-link');

        allLinks.forEach(link => link.addEventListener('click', e => {
            e.preventDefault();
            const title = link.getAttribute('href').toLocaleLowerCase().replace(/\s/g, '_').replace('#', '');

            const searchElement = document.querySelector(`span[id='${title}']`);
        
            const searchElementTop = searchElement.getBoundingClientRect().top + window.scrollY - 20 - headerHeight;
            
            window.scrollTo(0, searchElementTop);
        }))
    },
    highlightElements: () => {
        const blogSections = document.querySelectorAll('.article__content h2, .article__content h3');

        if ( !blogSections ) return;

        let options = {
            rootMargin: "-70px",
            threshold: 1.0,
        };

        let callback = (entries, observer) => {
            entries.forEach((entry) => {
                if ( entry.isIntersecting ) {
                    const activeSideMenuElement = document.querySelector(`a.ez-toc-link[title="${ entry.target.textContent }"]`)
                    if ( activeSideMenuElement ) {

                        const prevSideMenuElement = document.querySelector(`a.ez-toc-link.var_active`);
                        prevSideMenuElement?.classList.remove('var_active');

                        activeSideMenuElement.classList.add('var_active');
                        activeSideMenuElement.scrollIntoView({block: "nearest", inline: "nearest"});
                    }
                }
            });
          };
        
        let observer = new IntersectionObserver(callback, options);

        blogSections.forEach(blogSection => {
            observer.observe(blogSection);
        })
    }
}

export default blogSideBar;