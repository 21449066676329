const faq = {
    settings: {
        wrappers: '.faq-wrapper',
    },
    init: () => {
        const wrappers = document.querySelectorAll(faq.settings.wrappers);

        if (!wrappers)
            return;

        wrappers.forEach(wrapper => {
            wrapper.classList.remove('open');

            wrapper.addEventListener('click', () => {
                wrapper.classList.toggle('open');
            });
        });
    }
}

export default faq;