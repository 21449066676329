const copy = {
    settings: {
        selector: '.copy-element',
        attribute: 'data-copy'
    },
    init: () => {
        const elements = document.querySelectorAll(copy.settings.selector);

        if (!elements)
            return;

        elements.forEach(element => {
            element.addEventListener('click', () => {
                const attribute_value = element.getAttribute(copy.settings.attribute);
                copy.writeIntoClipboard(attribute_value, element);
            });
        });
    },
    writeIntoClipboard: (text_data, element) => {
        if (!navigator.clipboard) {
            copy.fallbackCopyTextToClipboard(text_data, element);
            return;
        } 

        copy.newNavigatorClipboardCopying(text_data, element);
    },
    newNavigatorClipboardCopying: (text_data, element) => {
        navigator.clipboard.writeText(text_data).then(function() {
            copy.successfulCopying(element);
        }, function() {
            console.error("Unable to write to clipboard.");
        });
    },
    fallbackCopyTextToClipboard: (text_data, element) => {
        var textArea = document.createElement("textarea");
        textArea.value = text_data;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            copy.successfulCopying(element);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    },
    successfulCopying: (element) => {
        element.classList.add('copied');

        setTimeout(() => {
            element.classList.remove('copied');
        }, 600);
    }
}

export default copy;