import Swiper, { Navigation, Pagination } from 'swiper';

const carousel = {
    init: () => {
        let carousels = document.querySelectorAll('.carousel');
        if (!carousels)
            return;

        carousels.forEach((carousel) => {
            let carouselObj = new Swiper(carousel, {
                modules: [ Navigation, Pagination ],
                slidesPerView: 3,
                spaceBetween: 26,
                loop: true,
                navigation: {
                    nextEl: carousel.closest('.carousel-content').querySelector('.carousel__arrow--right'),
                    prevEl: carousel.closest('.carousel-content').querySelector('.carousel__arrow--left'),
                },
                pagination: {
                    el: carousel.querySelector('.carousel__pagination'),
                    clickable: true,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },
                    650: {
                        slidesPerView: 2,
                        spaceBetween: 26
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 26
                    }
                }
            });

            let mobilePrev = carousel.closest('.carousel-content').querySelector('.carousel__arrow--mobile.carousel__arrow--left');
            mobilePrev.addEventListener('click', () => {
                carouselObj.slidePrev();
            });

            let mobileNext = carousel.closest('.carousel-content').querySelector('.carousel__arrow--mobile.carousel__arrow--right');
            mobileNext.addEventListener('click', () => {
                carouselObj.slideNext();
            });
        });
    }
}

export default carousel;