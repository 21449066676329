const headerTransparent = {
    init: function () {
        const header = document.querySelector('.header--transparent');

        if (!header) {
            return;
        }

        document.addEventListener(
            'scroll',
            function () {
                if (window.scrollY > 10) {
                    header.classList.add('scrolled');
                } else {
                    header.classList.remove('scrolled');
                }
            }
        );

        this.handleWhiteOnChildrenHover();
    },

    handleWhiteOnChildrenHover: function () {
        const header = document.querySelector('.header--transparent');
        const hoverElements = document.querySelectorAll('.extended-menu__top_level_link.has_submenu, .extended-menu__submenu');

        if (!header || !hoverElements.length) return;

        hoverElements.forEach((element) => {
            element.addEventListener('mouseenter', () => {
                header.classList.add('scrolled');
            });
            element.addEventListener('mouseleave', () => {
                if (window.scrollY > 10) return;

                header.classList.remove('scrolled');
            });
        });

    }
}

export default headerTransparent;
